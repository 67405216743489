import { Component, Prop, Vue } from "vue-property-decorator";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";

@Component
export default class DfLinkButtonComponent extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  button!: DfContent;
  @Prop({ type: Boolean, default: false })
  textOnly!: boolean;

  get url(): string {
    const propertyUrl: string = Utils.getPropertyValue(this.button, Utils.PROPERTY_URL, "STRING");
    return !propertyUrl.startsWith("http") && !propertyUrl.startsWith("/") ? `/${propertyUrl}` : propertyUrl;
  }

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get label(): string {
    return Utils.getPropertyValue(this.button, Utils.PROPERTY_BUTTON_LABEL, "STRING");
  }

  get backgroundColor(): string {
    return Utils.getPropertyValue(this.button, Utils.PROPERTY_BUTTON_BACKGROUND_COLOR, "STRING");
  }

  get color(): string {
    return Utils.getPropertyValue(this.button, Utils.PROPERTY_BUTTON_COLOR, "STRING");
  }

  get iconClass(): string {
    return Utils.getPropertyValue(this.button, Utils.PROPERTY_BUTTON_ICON_CLASS, "STRING");
  }

  get visibility(): string {
    return Utils.getPropertyValue(this.button, Utils.PROPERTY_BUTTON_VISIBILITY, "STRING", "");
  }

  get visibilityClass(): string {
    const cordova: any = (<any>window).cordova || null;
    const buttonVisibility = this.visibility;
    let buttonClass: string = null;

    if (cordova && buttonVisibility === "MOBILE") {
      buttonClass = "";
    } else if (cordova && buttonVisibility === "DESKTOP") {
      buttonClass = "desktop-in-cordova";
    } else {
      buttonClass = buttonVisibility.toLowerCase();
    }

    return buttonClass;
  }

  get buttonStyle(): any {
    const style: any = {};

    if (this.backgroundColor) {
      style.backgroundColor = this.backgroundColor;
    }

    if (this.color) {
      style.color = this.color;
    }

    if (this.backgroundImage) {
      style.backgroundImage = `url('${this.backgroundImage}')`;
      style.backgroundColor = "transparent";
      style.backgroundRepeat = "no-repeat";
    }

    return style;
  }

  get backgroundImage(): string {
    return Utils.getPropertyImageUrl(this.button, Utils.PROPERTY_BACKGROUND_IMAGE, null);
  }

  private onClick() {
    if (this.url) {
      Utils.isExternalLink(this.url) ? window.open(this.url, "_blank") : this.$router.push({ path: this.url });
    }
  }
}
